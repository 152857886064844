html,
body,
#root {
  height: 100%;
  overflow: hidden;
  margin: 0;
}

@font-face {
  font-family: 'UglyTerminal';
  src: local('UglyTerminal'), url(./assets/fonts/ugly-terminal-8.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
